<footer class="relative bg-rfk-dark-blue pt-8 pb-6">
    <div class="container mx-auto px-4">
        <div class="flex flex-wrap text-left lg:text-left items-center">
            <div class="w-full md:w-1/2">
                <img src="../../../../assets/images/text-logo.webp" class="w-80 mx-auto md:mx-0"
                    alt="Remnant Farm Kombucha Text Logo">
                <!-- <div class="mt-6 lg:mb-0 mb-6">
                    <button
                        class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button">
                        <i class="fab fa-twitter"></i></button><button
                        class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button">
                        <i class="fab fa-facebook-square"></i></button><button
                        class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button">
                        <i class="fab fa-dribbble"></i></button><button
                        class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button">
                        <i class="fab fa-github"></i>
                    </button>
                </div> -->
            </div>

            <div class="w-full md:w-6/12 px-4 mt-2 lg:mt-0">
                <div class="flex flex-wrap items-top mb-6 text-center md:text-left">
                    <div class="w-full lg:w-1/2 px-4 ml-auto">
                        <h3 class="block uppercase text-gray-200 text-sm font-semibold mb-2 underline">Links</h3>
                        <ul class="list-unstyled text-gray-200">
                            <li>
                                <a class=" hover:text-gray-400 block pb-2 text-sm transition duration-300"
                                    href="https://remnantfarmkombucha.com/" target="_blank">Our Website</a>
                            </li>
                            <li>
                                <a class="hover:text-gray-400 block pb-2 text-sm transition duration-300"
                                    href="https://remnantfarmkombucha.com/our-love-story/" target="_blank">Our Love
                                    Story</a>
                            </li>
                            <li>
                                <a class="hover:text-gray-400 block pb-2 text-sm transition duration-300"
                                    href="https://remnantfarmkombucha.com/our-kombucha/" target="_blank">Our
                                    Kombucha</a>
                            </li>
                            <li>
                                <a class="hover:text-gray-400 block pb-2 text-sm transition duration-300"
                                    href="https://remnantfarmkombucha.com/our-location/" target="_blank">Our
                                    Locations</a>
                            </li>
                            <!-- <li>
                                <a class="text-gray-600 hover:text-gray-800 block pb-2 text-sm transition-all"
                                    href="https://remnantfarmkombucha.com/contact-us/" target="_blank">Contact Us</a>
                            </li> -->
                        </ul>
                    </div>

                    <div class="w-full lg:w-1/2 px-4 mt-2 lg:mt-0">
                        <h3 class="block uppercase text-gray-200 text-sm font-semibold mb-2 underline">Contact Us</h3>
                        <ul class="list-unstyled text-sm">
                            <li>
                                <a class="flex justify-center md:justify-start text-gray-200 hover:text-gray-400 hover:cursor-pointer transition duration-300 pb-2"
                                    href="mailto: info@remnantfarm.com">
                                    <fa-icon [icon]="emailIcon" class="mr-2"></fa-icon>
                                    info@remnantfarm.com
                                </a>
                            </li>
                            <li>
                                <a class="flex justify-center md:justify-start text-gray-200 hover:text-gray-400 hover:cursor-pointer transition duration-300 pb-2"
                                    href="tel:571-288-9392">

                                    <fa-icon [icon]="phoneIcon" class="mr-2"></fa-icon>
                                    (571) 288-9392

                                </a>
                            </li>
                            <li>
                                <a class="flex justify-center md:justify-start text-gray-200 hover:text-gray-400 hover:cursor-pointer transition duration-300 pb-2"
                                    href="https://www.google.com/maps/place/6620+James+Madison+Hwy,+Haymarket,+VA+20169/@38.8135043,-77.6437552,18.67z/data=!4m6!3m5!1s0x89b6660e87f0da6b:0xa27ebe39ad19ebc9!8m2!3d38.813483!4d-77.6429817!16s%2Fg%2F11c21tc6d1?entry=ttu"
                                    target="_blank">
                                    <address>
                                        <fa-icon [icon]="locationIcon" class="mr-2"></fa-icon>
                                        6620 James Madison Highway Haymarket, VA 20169
                                    </address>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-2 lg:my-6 border-gray-400">
        <div class="flex flex-wrap items-center md:justify-between justify-center">
            <div class="w-full mx-auto text-center md:text-left">
                <div class="flex flex-col-reverse md:flex-row justify-between align-middle">
                    <div class="text-sm text-gray-400 font-light py-1">
                        Built by <a href="https://recursion.tech/" target="_blank">Recursion Tech</a><br />
                        Copyright © Remnant Farm Kombucha Inc.
                    </div>
                    <div class="mx-auto md:mx-0">
                        <a href="https://apps.apple.com/us/app/Remnant-Farms-Kombucha/id6451122115" target="_blank">
                            <img src="../../../../assets/icons/download-app-store.png"
                                alt="Link to download Remnant Farm Kombucha iOS app" class="w-20 md:w-32">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>