import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe?: Stripe;

  /**
   * Initialize the tripe variable
   */
  constructor() {
    this.initializeStripe();
  }

  /**
   * Initialize the stripe variable with the stripe instance
   * loded in using the stripe public key variable (stored in the environment)
   */
  private async initializeStripe() {
    try {
      this.stripe = await loadStripe(environment.stripePublicKey);
    } catch (error) {
      console.error('Stripe Initialization Error: ', error);
    }
  }

  /**
   * Get the stripe instance variable
   * 
   * This will give you access to performing actions using the 
   * stripe instance such as managing checkouts, customers, payments, etc.
   * 
   * @returns The stripe instance
   *  or undefined if an error occured when loading the stripe instance
   */
  getStripeInstance(): Stripe | undefined {
    return this.stripe;
  }
}