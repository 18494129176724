import { Component } from '@angular/core';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-authentication-layout',
  templateUrl: './user-authentication-layout.component.html',
  styleUrls: ['./user-authentication-layout.component.scss']
})
export class UserAuthenticationLayout {
  faBackward = faBackward;
  faForward = faForward;

  constructor(public authService: AuthService, public router: Router) { }

  ngOnInit(): void {
      if (this.authService.isLoggedIn) {
          this.router.navigate(["/", "account"]);
      }
  }
}
