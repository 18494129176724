import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, map } from "rxjs";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import Product from "@data/interfaces/product.interface";
import Price from "@data/interfaces/price.interface";

const logPrefix = "[ProductsService]";

@Injectable({
  providedIn: "root",
})
export class ProductsService {

  constructor(private firestore: AngularFirestore) {}

  // TODO add better documentation with return types or all functions

  /**
   * Return an array of all products offered by RFK Kombucha
   * without their associated prices
   */
  getAllProductsNoPrices(): Observable<Product[]> {
    let _logPrefix = logPrefix + "[fetchAllProductsNoPrices]";
    console.log(_logPrefix + " Fetching all products...");

    const productsSubject = new BehaviorSubject<Product[]>([]); // Initialize a BehaviorSubject
    const productsData: Product[] = [];

    // Grab all of the products
    this.firestore
      .collection("products")
      .get()
      .subscribe((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let newProduct: Product = {
            id: doc.id,
            active: doc.data()["active"],
            name: doc.data()["name"],
            description: doc.data()["description"],
            images: doc.data()["images"],
            isHard:
              doc.data()["stripe_metadata_isHard"] === "true" ? true : false,
            tax_code: doc.data()["tax_code"],
          };

          productsData.push(newProduct);
        });
        productsSubject.next(productsData);
      });

    return productsSubject.asObservable();
  }

  /**
   * Return an array of all products offered by RFK Kombucha
   * with their associated prices
   */
  // fetchAllProductsPrices(): void {
  //   let _logPrefix = logPrefix + "[fetchAllProductsPrices]";
  //   console.log(_logPrefix + " Fetching all products and prices...")

  //   // Grab all of the products
  //   this.firestore
  //     .collection("products")
  //     .get()
  //     .subscribe((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         console.log(doc.id, " => ", doc.data());

  //         // Grab all of the prices for each product
  //         this.firestore
  //           .collection("products")
  //           .doc(doc.id)
  //           .collection("prices")
  //           .get()
  //           .subscribe((querySnapshot) => {
  //             querySnapshot.forEach((doc) => {
  //               console.log(doc.id, " => ", doc.data());
  //             });
  //           });
  //       });
  //     });
  // }

  /**
   * Grab all of the soft kombucha products
   */
  getSoftKombuchaProducts(): Observable<Product[]> {
    let _logPrefix = logPrefix + "[fetchSoftKombuchaProducts]";
    console.log(_logPrefix + " Fetching all soft kombucha products...");

    const productsSubject = new BehaviorSubject<Product[]>([]); // Initialize a BehaviorSubject
    const productsData: Product[] = [];

    // Create a query against the collection.
    var query = this.firestore.collection("products", (ref) =>
      ref.where("stripe_metadata_isHard", "==", "false")
    );

    // Execute the query
    query.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let newProduct: Product = {
          id: doc.id,
          active: doc.data()["active"],
          name: doc.data()["name"],
          description: doc.data()["description"],
          images: doc.data()["images"],
          isHard:
            doc.data()["stripe_metadata_isHard"] === "true" ? true : false,
          tax_code: doc.data()["tax_code"],
        };

        // Only add the product if it is active
        if (newProduct.active === true) {
          productsData.push(newProduct);
        }
      });
      productsSubject.next(productsData);
    });

    return productsSubject.asObservable();
  }

  /**
   * Grab all of the hard kombucha products
   */
  getHardKombuchaProducts(): Observable<Product[]> {
    let _logPrefix = logPrefix + "[fetchHardKombuchaProducts]";
    console.log(_logPrefix + " Fetching all hard kombucha products...");

    const productsSubject = new BehaviorSubject<Product[]>([]); // Initialize a BehaviorSubject
    const productsData: Product[] = [];

    // Create a query against the collection.
    var query = this.firestore.collection("products", (ref) =>
      ref.where("stripe_metadata_isHard", "==", "true")
    );

    // Execute the query
    query.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let newProduct: Product = {
          id: doc.id,
          active: doc.data()["active"],
          name: doc.data()["name"],
          description: doc.data()["description"],
          images: doc.data()["images"],
          isHard:
            doc.data()["stripe_metadata_isHard"] === "true" ? true : false,
          tax_code: doc.data()["tax_code"],
        };

        // Only add the product if it is active
        if (newProduct.active === true) {
          productsData.push(newProduct);
        }
      });
      productsSubject.next(productsData);
    });

    return productsSubject.asObservable();
  }

  getThreeRandomHardProducts(): Promise<Product[]> {
    return new Promise<Product[]>((resolve, reject) => {
      // Create a query against the collection.
      const query = this.firestore.collection("products", ref =>
        ref.where("stripe_metadata_isHard", "==", "true")
        .where("active", "==", true)
      );
  
      // Execute the query
      query.get().subscribe((querySnapshot) => {
        const allProducts: Product[] = [];
  
        querySnapshot.forEach((doc) => {
          const newProduct: Product = {
            id: doc.id,
            active: doc.data()["active"],
            name: doc.data()["name"],
            description: doc.data()["description"],
            images: doc.data()["images"],
            isHard: doc.data()["stripe_metadata_isHard"] === "true",
            tax_code: doc.data()["tax_code"],
          };
          allProducts.push(newProduct);
        });
  
        // Now that we have all products, select three random ones.
        if (allProducts.length >= 3) {
          const randomIndices = this.getRandomIndices(allProducts.length, 3);
          const randomProducts = randomIndices.map(index => allProducts[index]);
          resolve(randomProducts);
        } else {
          // If there are less than 3, return all the products.
          resolve(allProducts);
        }
      });
    });
  }
  

  getRandomIndices(arrLength, count: number): number[] {
    if (count > arrLength) {
      throw new Error("Count cannot be greater than the array's length");
    }
  
    const indices = Array.from({ length: arrLength }, (_, i) => i);
    for (let i = indices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [indices[i], indices[j]] = [indices[j], indices[i]]; // Swap indices[i] and indices[j]
    }
    
    return indices.slice(0, count);
  }

  /**
   * Get the product by its id
   *
   * @param id the id of the product to get
   */
  getProductById(id: string): Observable<Product> {
    let _logPrefix = logPrefix + "[getProductById]";
    console.log(_logPrefix + " Fetching product with id: " + id);

    let productToReturn = new Observable();

    let newProduct: Product = {
      id: "-1",
      active: false,
      name: "",
      description: "",
      images: [],
      isHard: false,
      tax_code: "",
    };

    return this.firestore
      .collection("products")
      .doc(id)
      .get()
      .pipe(
        map(doc => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          let newProduct= {
            id: doc.id,
            active: doc.data()["active"],
            name: doc.data()["name"],
            description: doc.data()["description"],
            images: doc.data()["images"],
            isHard:
              doc.data()["stripe_metadata_isHard"] === "true" ? true : false,
            tax_code: doc.data()["tax_code"],
          };
          
          return newProduct;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          return newProduct;
        }
      }));

      // NOTE: This function is different than the rest BEWARE
  }

  /**
   * Get the product and price by its id
   *
   * @param id the id of the product to get the price of
   */
  getPricesForProductById(id: string): Observable<Price[]> {
    let _logPrefix = logPrefix + "[getPricesForProductById]";
    console.log(_logPrefix + " Fetching prices for product with id: " + id);

    const pricesSubject = new BehaviorSubject<Price[]>([]); // Initialize a BehaviorSubject
    const pricesData: Price[] = [];

    // Grab all of the prices for each product
    this.firestore
      .collection("products")
      .doc(id)
      .collection("prices")
      .get()
      .subscribe((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let newPrice: Price = {
            id: doc.id,
            active: doc.data()["active"],
            description: doc.data()["description"],
            amount: doc.data()["unit_amount"] / 100,
          };

          if (newPrice.active === true) {
            pricesData.push(newPrice);
          }
        });
        pricesSubject.next(pricesData);
      });

    return pricesSubject.asObservable();
  }
}

// getPriceDetailsForProduct(productId: string, priceId: string): Observable<Price> {
//   let _logPrefix = logPrefix + "[getPriceDetailsForProduct]";
//   console.log(_logPrefix + " Fetching price details for product with id: " + productId + " and price id: " + priceId);

// }