import { Component } from '@angular/core';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'user-mobile-navbar',
  templateUrl: './user-mobile-navbar.component.html',
  styleUrls: ['./user-mobile-navbar.component.scss']
})
export class UserMobileNavbarComponent {
  showMenu: boolean = false;
  cartIcon = faCartShopping

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
