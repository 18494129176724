import { Injectable } from "@angular/core";
import { Coordinate } from "@data/interfaces/coordinate.interface";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class PostalCodeService {
  private google_maps_api_key = environment.mapsAPIKey;

  constructor() {}

  /**
   * Check that a given string is a valid united states postal code
   *
   * @param postalCode The user's postal code
   * @returns true if the string is a valid united states postal code
   */
  isValidPostalCodeFormat(postalCode: string): boolean {
    const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return regexp.test(postalCode);
  }

  /**
   * Determine if a given postal code is within the
   * store's delivery range
   *
   * @param postalCode The user's postal code
   */
  async canShipToPostalCode(postalCode: string): Promise<number> {
    let userCoordinate: Coordinate =
      await this.getLongitudeAndLatitudeFromPostalCode(postalCode).then(
        (point: Coordinate) => {
          return point;
        }
      );

    // 38.81365852735173, -77.6430182397584
    let shippingCenterCoordinate: Coordinate = {
      longitude: -77.6430182397584,
      latitude: 38.81365852735173,
    };

    // Calculate the distance between the user and the shipping center
    let distance = this.haversineDistance(
      userCoordinate,
      shippingCenterCoordinate
    );
    return distance;
  }

  /**
   * Get the longitude and latitude of a given postal code
   *
   * @param postalCode The user's postal code
   * @returns A point in the form [latitude, longitude]
   * TODO: Find a better return type to return errors
   */
  getLongitudeAndLatitudeFromPostalCode(
    postalCode: string
  ): Promise<Coordinate> {
    return fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        postalCode +
        "&key=" +
        this.google_maps_api_key
    ).then((response) => {
      console.log(response);
      return response.json().then((data) => {
        console.log(data);
        let point: Coordinate = {
          latitude: data.results[0].geometry.location.lat,
          longitude: data.results[0].geometry.location.lng,
        };
        return point;
      });
    });
  }

  /**
   * Calculate the distance in miles between
   *  2 points using the longitude and latitude.
   *
   * @param point1.latitude Lattitude of point 1
   * @param point1.longitude Longitude of point 1
   * @param point2.latitude Lattitude of point 2
   * @param point2.longitude Longitude of point 2
   * @returns  The distance between the two points in miles
   */
  haversineDistance(point1: Coordinate, point2: Coordinate): number {
    function toRad(x) {
      return (x * Math.PI) / 180;
    }

    var lon1 = point1.longitude;
    var lat1 = point1.latitude;

    var lon2 = point2.longitude;
    var lat2 = point2.latitude;

    var R = 6371; // km

    var x1 = lat2 - lat1;
    var dLat = toRad(x1);
    var x2 = lon2 - lon1;
    var dLon = toRad(x2);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    // Convert to miles
    d /= 1.60934;

    return d;
  }

  /**
   * Return a message to the user based on their postal code
   *
   * There are 3 possible messages:
   * 1. The user has not entered a valid postal code
   * 2. The user has entered a valid postal code, but it is too far away
   * 3. The user has entered a valid postal code, and it is close enough
   */
  getShippingMessage(postalCode: string): Promise<String> {
    return this.canShipToPostalCode(postalCode).then(
      (distance: number) => {
        // Only ship to locations less than 15 miles away
        let cleanDistance = distance.toFixed(2);
        let canShipToPostalCode: boolean = distance < 20;

        if (canShipToPostalCode) {
          // Format the distance to 2 decimal places
          return `You are eligible for delivery!`;
        } else {
          return `Unfortunately we do not offer delivery to you at this time. You can still place a pickup order.`;
        }
      },
      (error) => {
        console.error(error);
        return "Please enter a valid postal code";
      }
    );
  }
}
