
  // This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrets

  export const environment = {
    production: 'false',
    stripePublicKey: 'pk_test_51NbOlsK5rzKAMlnHZdRcupIQUNyPDNGAb8is6zORcjyioCIcE2wBjwJhzFEKBj4zlxSAZ7W2ViDkIH4mlYZ9yDFw00lNTNv4Oo',
    createCheckoutSessionURL: 'https://us-central1-remnantfarmkombucha-dev.cloudfunctions.net/createCheckoutSession',
    firebase: {
        apiKey: 'AIzaSyDqjs2a49tDY2mzHwPKmiQA_5PQRXYjS04',
        authDomain: 'remnantfarmkombucha-dev.firebaseapp.com',
        projectId: 'remnantfarmkombucha-dev',
        storageBucket: 'remnantfarmkombucha-dev.appspot.com',
        messagingSenderId: '138502631378',
        appId: '1:138502631378:web:6dcca9a7cbe4577bdb220b',
        measurementId: 'G-YQKRWQN5QW'
    },
    mapsAPIKey: "AIzaSyDqjs2a49tDY2mzHwPKmiQA_5PQRXYjS04"
  };
