import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  firebaseErrors,
  getFirebaseErrorMessage,
} from "@data/constants/firebase-auth-error-codes";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import { Customer } from "@data/interfaces/customer.interface";
import { CustomerLocalStore } from "@data/interfaces/customer-local-store.interface";
import { Observable } from "rxjs";

const logPrefix = "[AuthService]";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  firebaseAuthErrorCodes = firebaseErrors;

  /**
   * AuthService constructor.
   * @param afAuth a Firebase Authentication object via AngularFireAuth.
   * @param router the app's Angular router.
   */
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private firestore: AngularFirestore
  ) { }

  /**
   * Returns true if the user is logged in and their email is verified.
   *
   * @returns True if the user is logged in and their email is verified.
   */
  get isLoggedIn(): boolean {
    let _logPrefix = `${logPrefix}[isLoggedIn]`;
    const user = JSON.parse(localStorage.getItem("customer")!);
    console.log(`${_logPrefix} User is logged in:`, user);
    return user !== null;
  }

  /**
   * Calls afAuth's signInWithEmailAndPassword method to sign in the user and
   * takes action accordingly.
   * @param email The email to use to attempt to sign in.
   * @param password The password to use to attempt to sign in.
   * @return null if the user has logged in successfully, otherwise
   *  a helpful error message will be displayed to the user
   */
  SignIn(email: string, password: string): Promise<string> {
    let _logPrefix = `${logPrefix}[SignIn]`;

    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          // If the result is valid then add the user to local storage
          if (result) {
            console.log(`${_logPrefix} Sign in successfuly`);
            this.getCustomerDataFromFirestore(result.user).then((customer) => {
              let customerLocalStore: CustomerLocalStore = {
                id: customer.id,
                stripeId: customer.stripeId,
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
              };

              console.log(`${_logPrefix} Saving details in local storage`);
              localStorage.setItem(
                "customer",
                JSON.stringify(customerLocalStore)
              );
              JSON.parse(localStorage.getItem("customer")!);
              resolve(result.user.email);
            });
          } else {
            reject("Unable to sign in. Please try again later.");
          }
        })
        .catch((error) => {
          console.log(`${_logPrefix} Error signing in:`, error);
          reject(getFirebaseErrorMessage(error.code));
        });
    });
  }

  /**
   * Sends a verification email to the currently logged in user.
   * @param actionCodeSettings Object containing additional settings for the
   *  afAuth's sendEmailVerification method.
   */
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        // this.router.navigate(["verify-email-address"]);
      });
  }

  /**
   * Sends an email to the provided email address with instructions to
   * reset their account password.
   * @param passwordResetEmail The email to send the reset password email to.
   */
  forgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert("Password reset email sent, check your inbox.");
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /**
   *
   * @param newUser A User object containing the user's data.
   * @param email The User's email address.
   * @param password The User's password.
   * @returns Null if no error, or a string containing the error message.
   */
  signUp(
    newCustomer: Customer,
    email: string,
    password: string
  ): Promise<String> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {
          newCustomer.id = result.user.uid;
          newCustomer.emailVerified = result.user.emailVerified;
          newCustomer.dateCreated = result.user.metadata.creationTime;

          // Create new object in database
          this.firestore
            .collection("customers")
            .doc(result.user.uid)
            .set(newCustomer, { merge: true });

          // Send verification email
          this.SendVerificationMail();

          let customerLocalStore: CustomerLocalStore = {
            id: newCustomer.id,
            stripeId: newCustomer.stripeId,
            firstName: newCustomer.firstName,
            lastName: newCustomer.lastName,
            email: newCustomer.email,
          };

          localStorage.setItem("customer", JSON.stringify(customerLocalStore));
          JSON.parse(localStorage.getItem("customer")!);

          resolve(null);
        })
        .catch((error) => {
          reject(getFirebaseErrorMessage(error.code));
        });
    });
  }

  getCustomerDataFromLocalStorage(): CustomerLocalStore {
    return JSON.parse(localStorage.getItem("customer")!);
  }

  /**
   * Concatenate data stored in the firestore database
   *
   * This includes the user's street address
   */
  getFullCustomerData(): Promise<Customer> {
    return new Promise((resolve, reject) => {
      // Check that the user is logged in
      this.afAuth.authState.subscribe((fbUser: firebase.User) => {
        if (fbUser) {
          resolve(this.getCustomerDataFromFirestore(fbUser));
        } else {
          reject(null);
        }
      });
    });
  }

  /**
   *  Get the user's data from the firestore database
   *  the user data is located in the customers collection
   *
   * @param uid The user's uid
   */
  getCustomerDataFromFirestore(user: firebase.User): Promise<Customer> {
    let _logPrefix = `${logPrefix}[getCustomerDataFromFirestore]`;
    console.log(
      `${_logPrefix} Getting customer data from firestore for user:`,
      user.uid
    );
    return new Promise((resolve, reject) => {
      this.firestore
        .collection("customers")
        .doc(user.uid)
        .get()
        .subscribe((data) => {
          if (data) {
            let tempCustomer: Customer = {
              id: user.uid,
              stripeId: data.data()!["stripeId"],
              stripeLink: data.data()!["stripeLink"],
              email: data.data()!["email"],
              phone: data.data()!["phone"],
              firstName: data.data()!["firstName"],
              lastName: data.data()!["lastName"],
              role: data.data()!["role"],
              birthday: data.data()!["birthday"],
              emailVerified: user.emailVerified,
              dateCreated: user.metadata.creationTime,
              address: {
                line1: data.data()!["address"]["line1"],
                line2: data.data()!["address"]["line2"],
                city: data.data()!["address"]["city"],
                state: data.data()!["address"]["state"],
                postal_code: data.data()!["address"]["postal_code"],
                country: data.data()!["address"]["country"],
              },
            };

            resolve(tempCustomer);
          } else {
            reject(null);
          }
        });
    });
  }

  /**
   *  Get the user's role from the firestore database
   *
   * @returns The user's role
   */
  getUserRole(): Promise<number> {
    return new Promise((resolve, reject) => {
      // Check that the user is logged in
      this.afAuth.authState.subscribe((fbUser: firebase.User) => {
        if (fbUser) {
          this.firestore
            .collection("customers")
            .doc(fbUser.uid)
            .get()
            .subscribe((data) => {
              if (data) {
                resolve(data.data()!["role"]);
              } else {
                reject(null);
              }
            });
        } else {
          reject(null);
        }
      });
    });
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  updateCustomerData(uid: string, customer: Customer) {
    // Create new object in database
    this.firestore.collection("customers").doc(uid).update(customer);

    this.router.navigate(["/account"]);
  }

  /**
   * Signs out the logged in user, clears the local storage of any user data,
   * then navigates the user back to the Login page.
   */
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("customer");
      this.router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() => this.router.navigate(["/", "account"]));
    });
  }
}
