<div class="md:flex flex-col md:flex-row md:min-h-screen w-full">
    <div
        class="flex flex-col w-full md:w-64 text-gray-700 bg-gradient-to-b from-rfk-slate via-rfk-light-blue to-rfk-blue dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0">
        <div class="mx-auto w-full flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
            <div class="md:mx-auto flex justify-center items-center">
                <img src="../../../../../assets/images/main-logo.webp" alt="Remnant Farm Kombucha Logo" class="w-12 h-12 md:w-36 md:h-36 ">
            </div>
            <button class="rounded-lg md:hidden focus:outline-none focus:shadow-outline" (click)="open = !open">
                <svg *ngIf="!open" fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6 text-white">
                    <path fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clip-rule="evenodd"></path>
                </svg>
                <svg *ngIf="this.open" fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6 text-white">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </div>
        <nav class="flex-grow md:block pb-4 md:pb-0 md:overflow-y-auto px-2 py-2 rounded-md shadow" [class.block]="open"
            [class.hidden]="!open">
            <hr class="text-white mx-2 py-2 pt-2">
            <div class="text-white">
                <a class="block px-4 py-2 mt-2 text-lg font-semibold bg-transparent rounded-lg  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline transition-all duration-100"
                    [routerLink]="['/admin']">
                    <div class="flex">
                        <fa-icon [icon]="dashboardIcon" class="mr-2"></fa-icon>
                        <p>
                            Dashboard
                        </p>
                    </div>
                </a>
                <a class="block px-4 py-2 mt-2 text-lg font-semibold bg-transparent rounded-lg  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline transition-all duration-100"
                    [routerLink]="['/admin/orders']">
                    <div class="flex">
                        <fa-icon [icon]="ordersIcon" class="mr-2"></fa-icon>
                        <p>
                            Orders
                        </p>
                    </div>
                </a>
                <a class="block px-4 py-2 mt-2 text-lg font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline transition-all duration-100"
                    [routerLink]="['/admin/promo-codes']">
                    <div class="flex">
                        <fa-icon [icon]="promoCodesIcon" class="mr-2"></fa-icon>
                        <p>
                            Promo Codes
                        </p>
                    </div>
                </a>
                <a class="block px-4 py-2 mt-2 text-lg font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline transition-all duration-100"
                    [routerLink]="['/admin/products']">
                    <div class="flex">
                        <fa-icon [icon]="productsIcon" class="mr-2"></fa-icon>
                        <p>
                            Products
                        </p>
                    </div>
                </a>
            </div>
            <hr class="text-white">
            <div class="text-white">
                <a class="block px-4 py-2 text-lg font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline transition-all duration-100"
                    [routerLink]="['/']">
                    <div class="flex">
                        <fa-icon [icon]="homeIcon" class="mr-2"></fa-icon>
                        <p>
                            Home
                        </p>
                    </div>
                </a>
            </div>
            <!-- Menu links go here -->
        </nav>
    </div>
</div>

