import { Component } from '@angular/core';

@Component({
  selector: 'core-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  isVisible = true;

  ngOnInit(): void {
    // Check local storage to see if banner was previously closed
    const bannerState = localStorage.getItem('bannerState');
    if (bannerState === 'closed') {
      this.isVisible = false;
    }
  }

  closeBanner(): void {
    this.isVisible = false;
    // Store banner state in local storage
    localStorage.setItem('bannerState', 'closed');
  }

}
