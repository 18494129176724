import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CartItem } from "@data/interfaces/cart-item.interface";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private cartRef: AngularFirestoreCollection<CartItem>;
  private customerId: string;

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {
    if (this.authService.isLoggedIn) {
      this.customerId = this.authService.getCustomerDataFromLocalStorage().id;
      this.cartRef = this.firestore
        .collection("customers")
        .doc(this.customerId)
        .collection("cart");
    }
  }

  getCart(): Observable<CartItem[]> {
    return this.cartRef.get().pipe(
      map((cartItems) => {
        return cartItems.docs.map((doc) => {
          return doc.data() as CartItem;
        });
      })
    );
  }

  addCartItem(id: string, cartItem: CartItem): void {
    this.cartRef.doc(id).set(cartItem);
  }

  removeCartItem(id: string): void {
    this.cartRef.doc(id).delete();
    // this.db.object(`users/${this.user.uid}/cart/${cartItemId}`).remove();
  }

  clearCart(): void {
    console.log("removing all iems from cart");
    this.cartRef.get().forEach((doc) => {
      doc.forEach((item) => {
        item.ref.delete();
      });
    });
  }
}
