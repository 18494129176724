<div *ngIf="this.isLoading">
    <app-loading-icon></app-loading-icon>
</div>

<div *ngIf="!this.isLoading">
    <div class="flex flex-col md:flex-row">
        <core-admin-sidebar></core-admin-sidebar>
        <div class="p-4">
            <router-outlet></router-outlet>
        </div>
    </div> 
</div>