  <!-- [START] Breadcrumbs -->
  <div class="bg-rfk-dark-blue py-1 md:py-4">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 mb-2">
      <div class="flex items-center space-x-2 text-gray-200 text-sm">
        <a [routerLink]="['/']" class="hover:underline hover:text-gray-300">Home</a>
        <div *ngFor="let breadcrumb of breadcrumbs" class="flex items-center space-x-2">
            <span>
              <svg class="h-5 w-5 leading-none text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </span>
            <a class="hover:underline hover:text-gray-300"
              [routerLink]="breadcrumb.path">{{breadcrumb.label}}</a>
        </div>
        <span>
          <svg class="h-5 w-5 leading-none text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </span>
        <span>{{currentPage}}</span>
      </div>
    </div>
  </div>
  <!-- [END] Breadcrumbs -->