import { Component, Input } from '@angular/core';

export interface Breadcrumb {
  label: string;
  path: string[];
}

@Component({
  selector: 'shared-page-breadcrumbs',
  templateUrl: './page-breadcrumbs.component.html',
  styleUrls: ['./page-breadcrumbs.component.scss']
})
export class PageBreadcrumbsComponent {
  @Input() breadcrumbs!: Breadcrumb[];
  @Input() currentPage: string;
}
