import { Injectable, NgModule } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  Routes,
  RouterModule,
  TitleStrategy,
  RouterStateSnapshot,
} from "@angular/router";

import { NotFoundComponent } from "@core/components/not-found/not-found.component";

import {
  UserLayout,
  UserAuthenticationLayout,
  AdminLayout,
  BlankLayout,
} from "./layouts";

// Version 1.0.0 Launch Countdown
// if (environment.production === "true") {
//   // Only show the countdown
//   var routes: Routes = [
//     {
//       path: "**",
//       title: "RFK | Coming Soon",
//       component: LaunchCountdownComponent
//     },
//   ];
// }
// Application in in local or development mode

var routes: Routes = [
  {
    path: "",
    component: UserLayout,
    loadChildren: () => import("@modules/user").then((m) => m.UserModule),
  },
  {
    path: "auth",
    component: UserAuthenticationLayout,
    loadChildren: () =>
      import("@modules/user-authentication").then(
        (m) => m.UserAuthenticationModule
      ),
  },
  {
    path: "checkout",
    component: BlankLayout,
    loadChildren: () =>
      import("@modules/user-checkout").then((m) => m.UserCheckoutModule),
  },
  {
    path: "admin",
    component: AdminLayout,
    loadChildren: () => import("@modules/admin").then((m) => m.AdminModule),
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/",
  },
  {
    path: "**",
    title: "RFK | Not Found",
    component: NotFoundComponent,
  },
];

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title}`);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: TemplatePageTitleStrategy }],
})
export class AppRoutingModule {}
