import { Component } from '@angular/core';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'core-user-footer',
  templateUrl: './user-footer.component.html',
  styleUrls: ['./user-footer.component.scss']
})
export class UserFooterComponent {
  locationIcon = faLocationDot;
  phoneIcon = faPhone;
  emailIcon = faEnvelope;  
}
