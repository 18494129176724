import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appDateMonthYearMask]',
})
export class DateMonthYearMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
      // Remove all non-digits characters
      let newVal = event.replace(/\D/g, '');
    if (backspace) {
        newVal = newVal.substring(0, newVal.length - 1);
    }
    else {
        // Add leading zero to month if needed
        if (newVal.length === 1 && parseInt(newVal) > 1) {
          newVal = '0' + newVal;
        }
    
        // Insert slash after month if not present
        if (newVal.length === 2) {
          newVal += '/';
        } 
        else if (newVal.length > 2) {
          newVal = newVal.substring(0, 2) + '/' + newVal.substring(2);
        }
    
        // Truncate to first 7 characters (MM/YYYY)
        newVal = newVal.substring(0, 7);
    }


    // Update the model value
    this.ngControl.valueAccessor.writeValue(newVal);
    // If you also need to update the view with the caret at the end, use setSelectionRange
    // This is useful if your input element is an HTMLInputElement
    const inputElement = this.ngControl.valueAccessor['el'].nativeElement;
    if (inputElement.setSelectionRange) {
      const len = newVal.length;
      requestAnimationFrame(() => inputElement.setSelectionRange(len, len));
    }
  }

}
