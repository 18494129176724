import { Component } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayout {
  isLoading: boolean = true;

  constructor(private authService: AuthService, private router: Router) { 
  }
  
  ngOnInit() {
    if(this.authService.isLoggedIn) {
      this.authService.getUserRole().then((role) => {
        this.isLoading = false;
        if(role !== 10) {
          this.router.navigate(['/']);
        }
      });
    } 
  }
}
