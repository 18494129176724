// Angular imports
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { environment } from "@environments/environment";

// Guards
import { EnsureModuleLoadedOnceGuard } from "./guards/ensure-module-loaded-once.guard";

// Firebase
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/compat/analytics";

// Components
import {
  AdminSidebarComponent,
  LaunchCountdownComponent,
  NotFoundComponent,
  UserFooterComponent,
  UserNavbarComponent,
  UserDesktopNavbarComponent,
  UserMobileNavbarComponent,
  BannerComponent,
} from "./components";

// Libraries
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    RouterModule,
    FontAwesomeModule,
  ],
  declarations: [
    AdminSidebarComponent,
    LaunchCountdownComponent,
    NotFoundComponent,
    UserFooterComponent,
    UserNavbarComponent,
    UserDesktopNavbarComponent,
    UserMobileNavbarComponent,
    BannerComponent,
  ],
  exports: [
    UserFooterComponent,
    UserNavbarComponent,
    AdminSidebarComponent,
    BannerComponent,
    AngularFireAnalyticsModule
  ],
  providers: [
    ScreenTrackingService, 
    UserTrackingService
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Use the EnsureModuleLoadedOnceGuard constructor to check that the core module was not imported outside of root
    super(parentModule);
  }
}
