import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { PostalCodeService } from "@shared/services/postal-code.service";

@Component({
  selector: "shared-postal-code-input",
  templateUrl: "./postal-code-input.component.html",
  styleUrls: ["./postal-code-input.component.scss"],
})
export class PostalCodeInput implements OnInit {
  isValidPostalCode: boolean = false;
  postalCode: string = "";
  @Output() isValidPostalCodeEmitter = new EventEmitter<boolean>();
  @Output() postalCodeEmitter = new EventEmitter<string>();
  @Output() shippingMessage = new EventEmitter<string>();

  constructor(private postalCodeService: PostalCodeService) {}

  ngOnInit() {}

  /**
   * Save the user's typed input to a variable
   *
   * @param event Any user input into the textbox
   */
  onKeyPostalCodeBox(event: any) {
    this.postalCode = event.target.value;
    this.isValidPostalCode = this.postalCodeService.isValidPostalCodeFormat(this.postalCode);

    // If .removeCartItem
    if (this.isValidPostalCode) {
      this.postalCodeService.getShippingMessage(this.postalCode).then(
        (message: string) => {
          this.shippingMessage.emit(message);
          this.isValidPostalCodeEmitter.emit(this.isValidPostalCode);
          this.postalCodeEmitter.emit(this.postalCode);
        },
        (error) => {
          console.error(error);
          this.shippingMessage.emit( "An error occured. Please try again later.");
          this.isValidPostalCodeEmitter.emit(this.isValidPostalCode);
          this.postalCodeEmitter.emit(null);
        }
        );
      } else {
        this.shippingMessage.emit(null);
        this.isValidPostalCodeEmitter.emit(this.isValidPostalCode);
        this.postalCodeEmitter.emit(null);
    }
  }

  /**
   * Check that a given string is a valid united states postal code
   *
   * @param postalCode The user's postal code
   * @returns true if the string is a valid united states postal code
   */
  validatePostalCode(postalCode: string): boolean {
    const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return regexp.test(postalCode);
  }
}
