import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../page-breadcrumbs/page-breadcrumbs.component';

@Component({
  selector: 'shared-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
 @Input() title: string;
 @Input() image!: string;
 @Input() breadcrumbs!: Breadcrumb[];
 @Input() currentPage: string;
}
