<div
    class="bg-gradient-to-b from-rfk-dark-blue to-rfk-slate-600 text-white min-h-screen flex items-center z-10 relative">
    <!-- Background Bubbles -->
    <div class="bubbles w-[100%] md:w-[50%]">
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
    </div>

    <div class="container mx-auto p-4 flex-col justify-center items-cente text-center">
        <!-- Image -->
        <div class="mx-auto text-center p-4">
            <img src="../../../../assets/images/main-logo.webp" alt="Remnant Farm Kombucha Logo"
                class="mx-auto w-48 md:w-96 h-auto" />
        </div>

        <!-- Text -->
        <div class="mx-auto p-4">
            <h2 class="text-2xl md:text-4xl pb-4">Coming Soon!</h2>
            <p class="text-xl md:text-3xl text-white">
                Accepting Online Orders For Pickup And Delivery
                <br />
                <span class="text-rfk-orange">
                    Remnant Farm Kombucha
                </span>
            </p>
        </div>

        <!-- Countdown -->
        <div class="mx-auto w-fit">
            <div class="grid grid-flow-col gap-5 text-center auto-cols-max">
                <div class="flex flex-col p-2">
                    <span class="font-mono text-3xl md:text-5xl">
                        {{this.countdown.days}}
                    </span>
                    days
                </div>
                <div class="flex flex-col p-2">
                    <span class="font-mono text-3xl md:text-5xl">
                        {{this.countdown.hours}}
                    </span>
                    hours
                </div>
                <div class="flex flex-col p-2">
                    <span class="font-mono text-3xl md:text-5xl">
                        {{this.countdown.minutes}}
                    </span>
                    min
                </div>
                <div class="flex flex-col p-2">
                    <span class="font-mono text-3xl md:text-5xl">
                        {{this.countdown.seconds}}
                    </span>
                    sec
                </div>
            </div>
        </div>
    </div>
</div>