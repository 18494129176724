import { Component } from "@angular/core";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { AuthService } from "@shared/services/auth.service";
import { faGear } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "user-desktop-navbar",
  templateUrl: "./user-desktop-navbar.component.html",
  styleUrls: ["./user-desktop-navbar.component.scss"],
})
export class UserDesktopNavbarComponent {
  userIcon = faUser;
  adminIcon = faGear;
  userRole: number;

  constructor(private authService: AuthService) {
    this.authService.getUserRole().then((role) => {
      this.userRole = role;
    });
  }
}
