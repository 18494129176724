<nav id="navbar"
    class="sticky top-0 bg-white z-[100] py-4 border-b border-gray-300 shadow-lg">
    <div class="container flex items-center justify-between">

        <div class="flex items-center flex-shrink-0 text-white">
            <span class="font-semibold text-xl tracking-tight">
                <img src="../../../assets/images/recursion-tech.png" alt="Remnant Farm Kombucha Logo linked to home page"
                    class="w-auto h-14 max-h-16 hover:cursor-pointer" [routerLink]="['']" />
            </span>
        </div>
    
        <div class="flex-grow text-center">
            <div class="inline-flex">
                <ul class="flex flex-wrap p-1 md:p-2 gap-8 text-base lg:text-lg">
                    <li class="hover:cursor-pointer text-light rounded-full hover:bg-slate-100 px-2 py-1 transition-all duration-300 border-2 border-black"
                        [routerLink]="['/soft-kombucha']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true}">
                        <a>
                            Buy Soft Kombucha
                        </a>
                    </li>
                    <li class="hover:cursor-pointer text-light rounded-full hover:bg-slate-100 px-2 py-1 transition-all duration-300 border-2 border-black"
                        [routerLink]="['/hard-kombucha']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true}">
                        <a>
                            Buy Hard Kombucha
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    
        <div class="flex items-center align-middle">
    
            <div class="mr-4 hover:cursor-pointer rounded-full hover:bg-slate-100 p-1 transition-all duration-300"
                [routerLink]="['/account']">
                <img src="../../../../../assets/icons/user.svg" alt="User Profile Icon linked to account page"
                    class="w-6 h-6" />
            </div>
    
            <div class="flex rounded-full hover:bg-slate-100 p-1 transition-all duration-300 hover:cursor-pointer"
                [routerLink]="['/cart']">
                <img src="../../../../../assets/icons/cart.svg" alt="" class="w-6 h-6">
                <!-- <p>{{cartQuanitity}}</p> -->
            </div>
    
            <div class="ml-4 hover:cursor-pointer text-light rounded-full hover:bg-slate-100 p-1 transition-all duration-300 border-2 border-black"
                [routerLink]="['/admin']" *ngIf="this.userRole && this.userRole === 10">
                <fa-icon [icon]="adminIcon"></fa-icon>
                Admin Console
            </div>
        </div>
    </div>
</nav>