<nav class="sticky top-0 z-[30] duration-300 transform bg-white border-b border-gray-400">
    <div class="flex items-center justify-center bg-white p-6">
        <img src="../../../../../assets/images/recursion-tech.png" alt="Remnant Farm Kombucha Logo linked to home page" class="h-12" [routerLink]="''" (click)="toggleMenu()"/>
    </div>
    
    <div class="absolute right-8 top-9">

        <div class="flex rounded-full hover:bg-slate-100 p-1 transition-all duration-300 hover:cursor-pointer" [routerLink]="['cart']">
            <img src="../../../../../assets/icons/cart.svg" alt="Shopping Cart Icon linked to cart page" class="w-7 h-7">
        </div>
    </div>
    
    <!-- Toggle Icon -->
    <div class="absolute left-8 top-10">
        <button (click)="this.toggleMenu()">
            <!-- Hamburger Icon -->
            <svg class="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" *ngIf="!this.showMenu">
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
    
            <!-- Close Icon -->
            <svg class="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" *ngIf="this.showMenu">
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
        </button>
    </div>

    <!-- Dropdown Menu -->
    <div [ngClass]="{'slide-up': !showMenu, 'slide-down': showMenu}" class="transition-all duration-300 bg-light-canvas mobile-navbar">
        <div class="p-6 pt-0 text-center" [ngClass]="{'hidden': !showMenu}">
            <p class="text-xl hover:cursor-pointer py-1" (click)="toggleMenu()" [routerLink]="['soft-kombucha']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true}">Soft Kombucha</p>
            <p class="text-xl hover:cursor-pointer py-1" (click)="toggleMenu()" [routerLink]="['hard-kombucha']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true}">Hard Kombucha</p>
            <p class="text-xl hover:cursor-pointer py-1" (click)="toggleMenu()" [routerLink]="['account']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true}">Account</p>
        </div>
    </div>
</nav>