import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-launch-countdown',
  templateUrl: './launch-countdown.component.html',
  styleUrls: ['./launch-countdown.component.scss']
})
export class LaunchCountdownComponent implements OnInit {
  targetDate: Date = new Date('2023-11-01T00:00:00');
  countdown: any = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  constructor() { }

  ngOnInit() {
    this.updateCountdown();
    setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  updateCountdown() {
    const now = new Date();
    const timeDifference = this.targetDate.getTime() - now.getTime();

    if (timeDifference > 0) {
      this.countdown.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.countdown.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      this.countdown.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    } else {
      // Countdown is over
      this.countdown = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
  }

}
