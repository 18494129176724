// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// Services
import { AuthService, CartService, ModalService, PostalCodeService, ProductsService, StripeService } from './services';

// Components
import { LoadingIconComponent, LoadingOverlayComponent, ModalComponent, PageHeaderComponent, PostalCodeInput } from './components';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { DateMonthYearMaskDirective } from './directives/date-month-year.directive';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { PageBreadcrumbsComponent } from './components/page-breadcrumbs/page-breadcrumbs.component';


@NgModule({
  declarations: [
    PageHeaderComponent,
    LoadingIconComponent,
    ModalComponent,
    LoadingOverlayComponent,
    PostalCodeInput,
    DateMonthYearMaskDirective,
    PhoneMaskDirective,
    PhoneFormatPipe,
    PageBreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    AngularFireDatabaseModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    PageHeaderComponent,
    LoadingIconComponent,
    LoadingOverlayComponent,
    PostalCodeInput,
    ModalComponent,
    PhoneMaskDirective,
    DateMonthYearMaskDirective,
    PageBreadcrumbsComponent,
    PhoneFormatPipe
  ],
  providers: [
    CartService,
    AuthService,
    ProductsService,
    ModalService,
    PostalCodeService
  ]
})
export class SharedModule { }
