<!-- [START] Header with image -->
<div *ngIf="image" class="relative h-52 md:h-96 w-full overflow-hidden bg-black">
    <img src="{{image}}" alt="Cover photo for {{title}} page" class="absolute top-0 left-0 h-full w-full object-cover opacity-70">
    <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <h1 class="text-white animate-fade-up">{{title}}</h1>
    </div>
</div>
<!-- [END] Header with image -->

<!-- [START] Header with breadcrumbs -->
<div *ngIf="!image" class="w-full overflow-hidden bg-rfk-dark-blue">
    <shared-page-breadcrumbs [currentPage]="currentPage" [breadcrumbs]="breadcrumbs"></shared-page-breadcrumbs>
    <div class="w-full pb-8 overflow-hidden bg-rfk-dark-blue">
        <div class="w-full h-full flex items-center justify-center">
            <h1 class="text-white animate-fade-up">{{title}}</h1>
        </div>
    </div>
</div>
<!-- [END] Header with breadcrumbs -->