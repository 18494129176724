import { Component } from '@angular/core';
import { faChartLine, faFileInvoice, faPiggyBank, faWhiskeyGlass, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'core-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent {
  open = false;
  dashboardIcon = faChartLine;
  ordersIcon = faFileInvoice;
  promoCodesIcon = faPiggyBank;
  productsIcon = faWhiskeyGlass;
  homeIcon = faHome
}
